
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { UserRole } from "@/models/RootObjects/UserRole";
import Dash from "@/store/modules/Dash";
import { DataTableHeader } from "vuetify";
import Pricing from "@/components/CommodityPricing/NewPricing.vue";
import { RealTimePrice } from "@/models/RootObjects/RealTimePricesData";
import SelectedRowModal from "@/components/CommodityPricing/SelectedRowModal.vue";
import {} from "models";
import { MarketData } from "../NewCommodityPricing.vue";
import { axiosInstance, axiosInstanceNew } from "@/plugins/axios";

interface InstrumentData {
  instrument: Instrument;
}

interface Instrument {
  id: number;
  name: string;
  description: string;
  contracts: Contract[];
}
interface SubResponse {
  contractsByInstrument: { [key: string]: Contract[] };
  IsDelayed: boolean;
}

interface Contract {
  id: number;
  instrument_id: string;
  contractDateSeq: string;
  contractDate: string;
  contractName: string;
  contractExpiryDate: string;
  optionsAllowed: string;
  contractOptionExpiryDate: string;
  contractFirstDeliverDate: string | null;
  contractLastDeliveryDate: string | null;
  contractNoticeDate: string | null;
  nominal: string;
  strikeInterval: string;
  latestClosePrice: string;
  latestOpenPrice: string;
  latestLow: string;
  latestHigh: string;
  latestOpenInterest: string;
  latestVolatility: string;
  latestVolume: string;
  gmc: string;
  optioncalc: string;
  competition: string;
  jseContract: string;
  deferredcalc: string;
  tradebook: string;
  expiryOverride: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

@Component({
  components: {
    Pricing,
    SelectedRowModal,
  },
})
export default class PricingTab extends Vue {
  test = [];
  selectedInstruments: number[] = [];
  savedSubInstruments: number[] = [];
  @Prop() readonly originalItems!: MarketData[];
  @Prop() readonly loadingCommodities!: boolean;
  @Prop() readonly updateSubscribe!: () => void;
  activeModalItem: { rtp: MarketData } | null = null;
  openModal = false;
  openMenu(obj: { rtp: MarketData }): void {
    this.activeModalItem = obj;
    this.openModal = true;
  }

  swipeDirection = "None";
  swipe(
    direction: string,
    value: string,
    parent: Record<string, () => unknown | unknown>
  ): void {
    const index = this.getSelectedHeaders.findIndex(
      (val) => val.value == value
    );
    console.log(
      "Swiped ",
      direction,
      " and selected: ",
      value,
      "  found:",
      index,
      this.getSelectedHeaders
    );
    if (index == -1) return;
    switch (direction) {
      case "Left": {
        if (index == 0 || index == 1) break;
        const temp = this.getSelectedHeaders;
        temp.splice(index - 1, 2, temp[index], temp[index - 1]);
        Dash.setCommoditySelectedHeaders(temp);
        // const tempArr = this.getHeaders;
        // const justBefore = tempArr[index - 1];
        // tempArr[index] = justBefore;
        // tempArr[index - 1] = value;
        // // console.log("Swapped ", this.selectedHeaders, tempArr, index);
        // this.selectedHeaders = tempArr;
        break;
      }
      case "Right": {
        if (index == this.getSelectedHeaders.length - 1 || index == 0) break;
        const temp = this.getSelectedHeaders;
        temp.splice(index, 2, temp[index + 1], temp[index]);
        Dash.setCommoditySelectedHeaders(temp);
        // const tempArr = this.getHeaders;
        // const justAfter = tempArr[index + 1];
        // tempArr[index + 1] = value;
        // tempArr[index] = justAfter;
        // // console.log("Swapped ", this.selectedHeaders, tempArr, index);
        // this.selectedHeaders = tempArr;
        break;
      }
    }
    this.swipeDirection = direction;
    parent.focus();
    parent.blur();
    // console.log("After ", this.getHeaders, Object.keys(parent));
  }
  async deleteGroup(group: any) {
    const find = this.listOfInstruments
      .flatMap((e) => e.contracts)
      .filter((e) => e.contractName.includes(group));
    console.log("Delete all from group ", group, find);

    if (find.length > 0) {
      this.savedSubInstruments = this.savedSubInstruments.filter(
        (e) => find && find.find((a) => a.id == e) == null
      );
      this.selectedInstruments = this.savedSubInstruments;
      const update = await this.updateSavedSubscriptions();
      // console.log("Testing deleteRow", id, find);
    }
  }
  async deleteRow(id: string) {
    const find = this.listOfInstruments
      .flatMap((e) => e.contracts)
      .find((a) => {
        return a.contractName == id;
      });
    console.log();
    if (find) {
      this.savedSubInstruments = this.savedSubInstruments.filter(
        (e) => find && e != find.id
      );
      this.selectedInstruments = this.savedSubInstruments;
      const update = await this.updateSavedSubscriptions();
      console.log("Testing deleteRow", id, find);
    }

    // this.savedSubInstruments = this.savedSubInstruments.filter((e) => e != id);
  }
  closeInstruments() {
    this.chooseInstruments = false;
  }
  closeMenu(): void {
    this.activeModalItem = null;
    console.warn("Closing ");
    this.openModal = false;
  }
  async mounted() {
    await this.onOpenInstruments(true);
    await this.updateSavedSubscriptions();
  }
  activeTableHeaders: DataTableHeader[] = [];
  get getAllHeaders(): DataTableHeader[] {
    const temp: DataTableHeader[] = [
      {
        text: "Contract",
        align: "start",
        value: "ContractDate",
        groupable: false,
        sortable: true,
      },
      {
        text: "Change",
        align: "start",
        value: "Change",
        groupable: false,
      },

      {
        text: "B/QTY",
        align: "start",
        value: "QtyBid",
        groupable: false,
      },
      {
        text: "Bid",
        align: "start",
        value: "Bid",
        groupable: false,
      },

      {
        text: "Offer",
        align: "start",
        value: "Offer",
        groupable: false,
      },
      {
        text: "S/QTY",
        align: "start",
        value: "QtyOffer",
        groupable: false,
      },
      {
        text: "Volume",
        align: "start",
        value: "Volume",
        groupable: false,
      },
      {
        text: "OpenPrice",
        align: "start",
        value: "OpenPrice",
        groupable: false,
      },
      {
        text: "Last",
        align: "start",
        value: "Last",
        groupable: false,
      },
      {
        text: "High",
        align: "start",
        value: "Hi",
        groupable: false,
      },
      {
        text: "Low",
        align: "start",
        value: "Lo",
        groupable: false,
      },
    ];
    return temp;
    // }
  }
  get getDeletedRTP(): RealTimePrice[] {
    return Dash.getDeletedRealTimePrices;
  }
  get getDeletedGroups(): string[] {
    return Dash.getCommodityDeletedGroups;
  }

  get getFilteredRTP(): MarketData[] {
    return this.originalItems;
  }
  chooseHeaders = false;
  chooseInstruments = false;
  userRole: UserRole[] = [];
  checkDelayed(): boolean {
    const temp = this.getUserRole.findIndex((elem) => elem.name == "delayed");
    if (temp != -1) {
      return true;
    } else {
      return false;
    }
  }
  get getUserRole(): UserRole[] {
    return this.userRole;
  }
  get getSelectedHeaders(): DataTableHeader[] {
    return Dash.getCommodityHeaders;
    // return this.getAllHeaders;
  }
  @Watch("getSelectedHeaders", { immediate: true, deep: true })
  headersChanged(val: string[]): void {
    console.log("Changed headers ", val);
    this.activeTableHeaders = [];
    for (let i = 0; i < this.getSelectedHeaders.length; i++) {
      this.activeTableHeaders.push(this.getSelectedHeaders[i]);
    }
    this.activeTableHeaders.push({
      text: "Actions",
      align: "center",
      value: "actions",
      groupable: false,
    });
  }
  setSelectedHeaders(arr: DataTableHeader[]): void {
    Dash.setCommoditySelectedHeaders(arr);
  }
  setSelectedHeader(head: DataTableHeader): void {
    Dash.setCommoditySelectedHeader(head);
  }
  closeChooseHeader(): void {
    this.chooseHeaders = false;
  }
  refresh(): void {
    //this is a big issue. Cant just reload...
    window.location.reload();
  }
  listOfInstruments: Instrument[] = [];
  specialOrder = ["WMAZ", "YMAZ", "CORN", "WEAT", "SUNS", "SOYA", "SOYB"];

  sortInstruments(instruments: Instrument[]): Instrument[] {
    return instruments.sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;
      const indexA = this.specialOrder.indexOf(nameA);
      const indexB = this.specialOrder.indexOf(nameB);

      // Both items are in the special order
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      // Only the first item is in the special order
      if (indexA !== -1) {
        return -1;
      }

      // Only the second item is in the special order
      if (indexB !== -1) {
        return 1;
      }

      // Neither item is in the special order, sort alphabetically
      return nameA.localeCompare(nameB);
    });
  }
  @Watch("chooseInstruments")
  async onOpenInstruments(val: boolean): Promise<void> {
    if (val == true) {
      const res = await this.loadSubscriptions();
      const inst = await axiosInstance.get<InstrumentData[]>(
        process.env.VUE_APP_SERVER_BASE +
          "/api/usersubscriptions/getinsturments"
      );
      this.listOfInstruments = this.sortInstruments(
        inst.data.flatMap((data) => {
          const sortedContracts = data.instrument.contracts.sort((a, b) => {
            return (
              new Date(a.contractExpiryDate).getTime() -
              new Date(b.contractExpiryDate).getTime()
            );
          });

          // Return a new instrument object with the sorted contracts
          return {
            ...data.instrument,
            contracts: sortedContracts,
          };
        })
      );
      console.log("Openend Instruments ", res);
    } else {
      this.selectedInstruments = this.savedSubInstruments;
    }
  }
  async updateSavedSubscriptions(assignSelectedInstrumentsAfterSave?: boolean) {
    const body = this.selectedInstruments;
    console.log("Update to sub ", body);
    const form = new FormData();
    // body.forEach((e) => form.append("contracts[]", e));
    // form.append("contracts", body);
    const res = await axiosInstanceNew.post(
      process.env.VUE_APP_SERVER_BASE + "/api/usersubscriptions/save",
      {
        contracts: body,
      },
      {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      }
    );
    if (assignSelectedInstrumentsAfterSave == true) {
      this.savedSubInstruments = this.selectedInstruments;
    }

    this.closeInstruments();
    setTimeout(this.updateSubscribe, 2000);
    console.log("Updated sub ", res.data);
  }
  async loadSubscriptions(): Promise<void> {
    const res = await axiosInstance.get<SubResponse>(
      process.env.VUE_APP_SERVER_BASE +
        "/api/usersubscriptions/getUserSubscription"
    );
    this.savedSubInstruments = Object.values(res.data.contractsByInstrument)
      .flatMap((contracts) => contracts)
      .map((contract) => contract.id);
    this.selectedInstruments = this.savedSubInstruments;
    console.log("Contract ", this.selectedInstruments);
  }
}
